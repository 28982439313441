import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import { instance } from '../components/Utils';
import {notification, Button} from 'antd';
import { SmileOutlined } from '@ant-design/icons';

import Logo from "../assets/logo.png";

export default function Contact() {
  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

  let navigate = useNavigate();

  let [firstName, setFirstName] = useState("");
  let [firstNameError, setFirstNameError] = useState(false);
  let [firstNameErrorMsg, setFirstNameErrorMsg] = useState("");

  let [lastName, setLastName] = useState("");
  let [lastNameError, setLastNameError] = useState(false);
  let [lastNameErrorMsg, setLastNameErrorMsg] = useState("");

  let [email, setEmail] = useState("");
  let [emailError, setEmailError] = useState(false);
  let [emailErrorMsg, setEmailErrorMsg] = useState("");

  let [message, setMessage] = useState("");
  let [sendStatus, setSendStatus] = useState("none");  //none, sending, sent

  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      
        <Button style={{color: '#108ee9'}} size="small" onClick={() => api.destroy(key)}>
          Ok
        </Button>
    );

    api.success({
      message: 'Message Received',
      description:
        `Hi ${firstName}, we received your message and will get back to you shortly.`,
      btn,
      key,
      onClose: ()=>{navigate('/', {replace : true});},
      duration: 30,
      placement: 'top',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      
    });

  }

  async function submitHandler(){
    let valid_email = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{1,}$/; 
    let letters = /^[a-zA-Z]+$/;

    let badEntry = false;
    if(firstName == ""){
        setFirstNameError(true);
        setFirstNameErrorMsg("First Name is required");
        badEntry = true;
    }
    else if(!letters.test(firstName)){
        setFirstNameError(true);
        setLastNameErrorMsg("First Name can only contain letters");
        badEntry = true;
    }
    else{
        setFirstNameError(false);
    }

    if(lastName == ""){
        setLastNameError(true);
        setLastNameErrorMsg("Last Name is required");
        badEntry = true;
    }
    else if(!letters.test(lastName)){
        setLastNameError(true);
        setLastNameErrorMsg("Last Name can only contain letters");
        badEntry = true;
    }
    else{
        setLastNameError(false);
    }

    if(email == ""){
        setEmailError(true);
        setEmailErrorMsg("Email Address is required");
        badEntry = true;
    }
    else if(!valid_email.test(email)){
        setEmailError(true);
        setEmailErrorMsg("Email Address is invalid");
        badEntry = true;
    }
    else{
        setEmailError(false);
    }


    if(badEntry == false){
      setSendStatus("sending");
      let res = await instance.post("/saieagency/momentum-vending", {'first_name' : firstName, 'last_name' : lastName, 'email' : email, 'message' : message});
      await sleep(1000);
      setSendStatus("sent");
      openNotification();           
    }

  }


  return (
    <div className="bg-contact w-full   mobile:py-[32px] mobile:px-[32px] desktop:py-[79px] desktop:px-[120px]">
      {contextHolder}
      <div className="flex flex-col desktop:flex-row space-y-12 desktop:space-y-0 space-x-[0px] desktop:space-x-[220px] items-start w-full h-full">

          <div className="flex flex-col items-start desktop:h-full w-full mx-auto">
            <div className="text-center w-full">
              
              <div onClick={()=>{navigate('/', {replace : true}) }} className="flex justify-start cursor-pointer">
                    <img className="drop-shadow-[4px_4px_50px_rgba(0,132,255,1.0)] w-[161px]" src={Logo} alt="" />
              </div>
              
              <div className="pt-[132px] flex flex-col desktop:max-w-[420px] mx-auto">
              <h1 className="desktop:text-display-text mobile:text-display-text-mobile font-heading leading-regular text-white">
                Inquiry about our vending machines
              </h1>
              
              <p className="mobile:text-body-text-mobile desktop:text-body-text pt-[12px] text-white">
                Need a vending machine for your business? 
                Ask about our free vending machine installation and restocking service!
              </p>

              </div>
              
              <div className="hidden desktop:flex justify-center items-center pt-[48px]">
                <button onClick={()=>{navigate('/', {replace : true}) }} className="bg-home-btn   text-white texl-lg px-14 py-7 hover:opacity-75">
                Home
              </button>
              </div>
            
            </div>
          </div>
        
        <div className="flex flex-row h-full w-full justify-center items-start mobile:pt-[48px] tablet:pt-[80px] ">
          <div className="bg-white drop-shadow-[4px_4px_100px_rgba(0,132,255,0.78)] tablet:px-[74px] mobile:px-[48px] py-[22px] rounded-[20px] mobile:w-full tablet:w-[598px] min-h-min">
            <div className="" >
              <h1 className="tablet:text-heading-text mobile:text-heading-text-mobile font-bold">Contact us</h1>
              
              <div className="pt-[32px]">
                <input onChange={(e)=>{setFirstName(e.target.value); setFirstNameError(false);}} value={firstName}
                  className={`border ${firstNameError === true ? "border-red-600" : "border-gray-500"} hover:border-[#0084FF] focus:ring-4 focus:ring-[#0084FF] focus:ring-opacity-90 transition duration-300 mobile:text-body-text-mobile tablet:text-body-text mobile:w-full mobile:h-[35px] tablet:w-[450px] tablet:h-[71px] py-[26px] px-[23px] mobile:rounded-[10px] tablet:rounded-[20px]`}
                  placeholder="First Name"
                  type="text"
                />
                 <div className={`text-red-400 text-sm mt-2 ${firstNameError === true ? "flex" : "hidden"}`}>{firstNameErrorMsg}</div>
              </div>
              
              <div className="pt-[24px] ">
                <input onChange={(e)=>{setLastName(e.target.value); setLastNameError(false);} } value={lastName} 
                  className={`border ${firstNameError === true ? "border-red-600" : "border-gray-500"} hover:border-[#0084FF] focus:ring-4 focus:ring-[#0084FF] focus:ring-opacity-90 transition duration-300 mobile:text-body-text-mobile tablet:text-body-text mobile:w-full mobile:h-[35px] tablet:w-[450px] tablet:h-[71px] py-[26px] px-[23px] mobile:rounded-[10px] tablet:rounded-[20px]`}
                  placeholder="Last Name"
                  type="text"
                />
                <div className={`text-red-400 text-sm mt-2 ${lastNameError === true ? "flex" : "hidden"}`}>{lastNameErrorMsg}</div>
              </div>
              
              <div className="pt-[24px] ">
                <input onChange={(e)=>{setEmail(e.target.value); setEmailError(false);}} value={email}
                  className={`border ${firstNameError === true ? "border-red-600" : "border-gray-500"} hover:border-[#0084FF] focus:ring-4 focus:ring-[#0084FF] focus:ring-opacity-90 transition duration-300 mobile:text-body-text-mobile tablet:text-body-text mobile:w-full mobile:h-[35px] tablet:w-[450px] tablet:h-[71px] py-[26px] px-[23px] mobile:rounded-[10px] tablet:rounded-[20px]`}
                  placeholder="Email"
                  type="email"
                />
                <div className={`text-red-400 text-sm mt-2 ${emailError === true ? "flex" : "hidden"}`}>{emailErrorMsg}</div>
              </div>
              
              <div className="pt-[24px] ">
                <textarea onChange={(e)=>{setMessage(e.target.value);}} value={message}
                  className="flex border border-gray-500 hover:border-[#0084FF] focus:ring-4 focus:ring-[#0084FF] focus:ring-opacity-90 transition duration-300 mobile:text-body-text-mobile tablet:text-body-text mobile:w-full mobile:h-[200px] tablet:w-[450px] tablet:h-[290px] py-[26px] px-[23px] mobile:rounded-[10px] tablet:rounded-[20px]"
                  placeholder="Message"
                  type="textarea"
                />
              </div>

              <div className="pt-[18px] w-full">
                <div class="flex justify-end">
                  <button disabled={sendStatus === "sent"} onClick={()=>{submitHandler(); }} 
                    className="flex flex-row items-center justify-center min-w-fit mobile:w-[89px] mobile:h-[43px] tablet:w-[99px] tablet:h-[47px] tablet:text-body-text mobile:text-body-text-mobile leading-regular font-normal primary-bg text-white py-[10px] px-[10px] rounded-[20px] hover:bg-blue-600">
                    {sendStatus === "sending" ?
                      <svg class="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    :
                    sendStatus === "sent" ?
                    <svg className="mr-3" width="20" height="20" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.24256 4.76106C1.17099 4.76013 0.86214 4.29825 0.556219 3.73464L0 2.70987L0.144176 2.56571C0.223471 2.48641 0.4366 2.42153 0.617796 2.42153H0.947244L1.11286 2.94333L1.27847 3.46512L2.59644 2.02306C3.32132 1.22992 4.15867 0.450268 4.45721 0.290497L5 0L3.90454 1.2445C3.30204 1.92898 2.48589 3.00061 2.09088 3.62588C1.69587 4.25116 1.31412 4.76198 1.24256 4.76106Z" fill="white"/>
                    </svg>
                    :
                    <>
                    </>
                    }
                    <div>
                      { sendStatus === "sending" ? "Sending" : sendStatus === "sent" ? "Sent" : "Send"}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}
