import React from "react";
import { Link } from "react-router-dom";
import SnacksIcon from "../svgs/Snacks.jsx";
import DrinkIcon from "../svgs/DrinkSvg.jsx";
import HealthyIcon from "../svgs/Healthy.jsx";
import EnergyIcon from "../svgs/Energy.jsx";

import Logo from "../assets/logo.png";
import HamburgerMenu from "../assets/hamburger_menu.svg";
import VendingMachineBlur from "../assets/vending_machine_blur.svg";
import InstallationWorker from "../assets/worker_illustration.svg";
import Restocking from "../assets/restocking_machine-removebg.png";
import MaintenaceWorker from "../assets/maintenance_worker.svg";


import SnacksMachine from "../assets/snack_machine.png";
import DrinkMachine from "../assets/drink_machine.png";
import HealthyMachine from "../assets/healthy_machine.png";
import EnergyMachine from "../assets/energy_machine.png";


function Home() {
  const [current, setCurrent] = React.useState(0);
  const [show, setShow] = React.useState(false);

  const products = [
    {
      key : 1,
      name: "Snacks",
      icon: <SnacksIcon />,
    },
    {
      key : 2,
      name: "Drinks",
      icon: <DrinkIcon />,
    },
    {
      key : 3,
      name: "Healthy",
      icon: <HealthyIcon />,
    },
    {
      key : 4,
      name: "Energy",
      icon: <EnergyIcon />,
    },
  ];

  const data = [
    {
      key : 1,
      image: SnacksMachine,
      title: "Snacks",
    },
    {
      key : 2,
      image: DrinkMachine,
      title: "Drinks",
    },
    {
      key : 3,
      image: HealthyMachine,
      title: "Healthy",
    },
    {
      key : 4,
      image: EnergyMachine,
      title: "Energy",
    },
  ];


  const showAlert = () => {
    setShow(!show);
  }
  const onProductFunc = (itemIndex) => {
    setCurrent(itemIndex);
  };

  const sideoverFunc = () => {
    setShow(!show);
  };

  return (
    <div className="">
      <div className="h-[100vh] ">
        <div className="pt-[20px] tablet:px-[120px] mobile:px-[32px] ">
          <nav className="bg-white border-shadow rounded-full w-full tablet:px-[60px] tablet:py-[20px] mobile:py-[20px] mobile:px-[32px] flex items-center justify-between">
            <div className="">
              <img className="tablet:w-[161px] mobile:w-[80px]" src={Logo} alt="" />
            </div>
            <ul className="hidden text-base leading-regular desktop:flex items-center gap-x-[82px]">
              <div className="flex items-center gap-x-[59px]">
                <li>
                  <a
                    className="desktop:text-body-text mobile:text-body-text-mobile leading-regular  font-normal p-3 hover:bg-blue-600 hover:bg-opacity-40 rounded-xl"
                    href="#section-products"
                  >
                    Products
                  </a>
                </li>
                <li>
                  <a
                    className="desktop:text-body-text mobile:text-body-text-mobile leading-regular p-3 hover:bg-blue-600 hover:bg-opacity-40 rounded-xl"
                    href="#section-services"
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    className="desktop:text-body-text mobile:text-body-text-mobile leading-regular p-3 hover:bg-blue-600 hover:bg-opacity-40 rounded-xl"
                    href="#about-us"
                  >
                    About us
                  </a>
                </li>
              </div>

              <li>
                <Link
                  to="/contact-us"
                  className="desktop:text-body-text mobile:text-body-text-mobile leading-regular font-normal primary-bg text-white p-5 rounded-xl hover:bg-blue-600"
                >
                  GET A QUOTE
                </Link>
              </li>
            </ul>
            <div
              onClick={sideoverFunc}
              className="flex cursor-pointer desktop:hidden"
            >
              <img className="tablet:w-[48px]" src={HamburgerMenu} alt="" />
            </div>
          </nav>
        </div>
        {/* Slide Over for mobile menu */}
        <div onClick={sideoverFunc} className={show ? "h-screen w-screen lg:hidden block fixed bg-gray-500/60 top-0 left-0 z-30" : "h-screen w-screen fixed bg-gray-500/90 hidden top-0 left-0 z-30"}/>
        <div
          className={`${
            show
              ? "translate-x-[0%] lg:-translate-x-[100%] z-50  transition-all duration-300 pointer-events-auto fixed top-0 w-screen mobile:max-w-[70%] tablet:max-w-[500px]"
              : "-translate-x-[100%]  fixed top-0 transition-all duration-300 pointer-events-auto  w-screen max-w-[70%]"
          }`}
        >
          <div className="absolute right-4 top-8 -ml-8 flex pr-2 pt-4 mobile:-ml-10 mobile:pr-4">
            <button
              type="button"
              className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
            >
              <span
                onClick={sideoverFunc}
                className="absolute -inset-2.5"
              ></span>
              <span className="sr-only">Close panel</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M19.3335 0.66652C18.4448 -0.222173 17.0037 -0.222173 16.115 0.66652L10 6.78139L3.88503 0.66652C2.99632 -0.222173 1.55524 -0.222173 0.666531 0.66652C-0.222177 1.55521 -0.222177 2.99627 0.666531 3.88497L6.7815 10.0002L0.666531 16.115C-0.222177 17.0037 -0.222177 18.4448 0.666531 19.3335C1.55524 20.2222 2.99632 20.2222 3.88503 19.3335L10 13.2186L16.115 19.3335C17.0037 20.2222 18.4448 20.2222 19.3335 19.3335C20.2222 18.4448 20.2222 17.0037 19.3335 16.115L13.2185 10.0002L19.3335 3.88529C20.2222 2.9966 20.2222 1.55554 19.3335 0.666845V0.66652Z"
                  fill="#FF5353"
                />
              </svg>
            </button>
          </div>

          <div className="flex px-8 py-12 h-[100vh] flex-col justify-between bg-white ">
            <div className=" ">
              <img className="mobile:w-[150px] tablet:w-[200px]" src={Logo} alt="" />
            </div>
            <ul className=" text-base leading-4 flex flex-col items-start justify-between gap-y-20 mt-10   w-full">
              <div className="flex flex-col text-[#0084FF] items-start gap-y-12">
                <li>
                  <a
                    className="tablet:text-body-text mobile:text-body-text-mobile leading-regular w-full  font-normal p-3 hover:bg-blue-600 hover:bg-opacity-40 rounded-xl"
                    href="#section-products"
                  >
                    Products
                  </a>
                </li>
                <li>
                  <a
                    className="tablet:text-body-text mobile:text-body-text-mobile leading-regular font-normal p-3 hover:bg-blue-600 hover:bg-opacity-40 rounded-xl"
                    href="#section-services"
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    className="tablet:text-body-text mobile:text-body-text-mobile leading-regular font-normal p-3 hover:bg-blue-600 hover:bg-opacity-40 rounded-xl"
                    href="#about-us"
                  >
                    About us
                  </a>
                </li>
              </div>

              <li className="text-center w-full"></li>
            </ul>

            <div className="">
              <Link
                to="/contact-us"
                className="tablet:text-body-text mobile:text-body-text-mobile leading-regular font-normal primary-bg text-white p-5 rounded-xl hover:bg-blue-600"
              >
                GET A QUOTE
              </Link>
            </div>
          </div>
        </div>
        {/* HERO SECTION */}
        <section className="flex grow desktop:flex-row mobile:flex-col justify-center desktop:space-x-[120px] tablet:px-[120px] mobile:px-[32px] pt-[66px] desktop:pb-[66px] mobile:pb-[48px]">
            <div className="flex items-center desktop:mx-0 mx-auto desktop:w-[427px] mobile:max-w-[329px] tablet:max-w-[600px] mobile:text-center desktop:text-left ">
              <div>
                <h1 className="desktop:text-left mobile:text-center desktop:justify-center mobile:text-display-text-mobile desktop:text-display-text font-heading leading-regular">
                  Get A Vending Machine For Your Business!
                </h1>
                <p className="desktop:text-left mobile:text-center mobile:text-body-mobile pt-[16px]">
                  We provide professional services for vending machine
                  installation and maintenance. We remove the hassle of acquiring
                  and managing a vending machine.
                </p>

                <div className="mobile:flex mobile:items-center mobile:justify-center pt-[32px] desktop:justify-start">
                  <Link
                    to="/contact-us"
                    className="primary-bg   text-white p-4 rounded-2xl text-lg text-center desktop:mx-0 mx-auto   w-fit "
                  >
                    Contact us
                  </Link>
                </div>
              </div>
            </div>

            <div className="tablet:w-[500px] mobile:w-[250px] mobile:pt-[66px] mx-auto">
                <img src={VendingMachineBlur} alt=""/>
            </div>

          
        </section>
        {/* PRODUCT SECTION */}
        <section id="section-products" className="mobile:px-[32px] tablet:px-[120px] tablet:py-[66px] mobile:py-[48px] bg-opacity-[30%] bg-lightBlue">
          <div className="space-y-[32px] max-w-[960px] mx-auto">
            <div className=" justify-center space-y-[8px] text-center">
              <h1 className="tablet:text-heading-text mobile:text-heading-text-mobile font-bold">Products</h1>
              <p className="tablet:text-body-text mobile:text-body-text-mobile mobile:w-[255px] tablet:w-[350px] mx-auto">
                We provide a wide array of products completely customizable. Each machine can be completely different.
              </p>
            </div>
            <div className="mobile:grid grid-cols-2 justify-items-center mobile:mx-auto mobile:gap-x-[20px] mobile:gap-y-[20px] desktop:flex justify-center desktop:space-x-[20px]">
              {products.map((item, index) => (
                <div key={index}
                  onClick={() => onProductFunc(index)}
                  className={
                    index === current
                      ? " bg-gray-grediant-active py-[18px] px-[26px] w-full h-full text-white cursor-pointer  hover:text-white flex flex-col space-y-[10px] items-center justify-center"
                      : "bg-gray-grediant py-[18px] px-[26px] w-full h-full cursor-pointer  hover:text-white flex flex-col space-y-[10px] items-center justify-center"
                  }
                >
                  <div className="">{item.icon}</div>
                  <p className="text-body-text">{item.name}</p>
                </div>
              ))}
            </div>
            <div className="flex mobile:flex-col tablet:flex-row mobile:justify-items-center justify-center tablet:space-x-[100px]">
              <div className="mobile:mx-auto tablet:mx-0 min-w-[150px] w-[150px] ">
                <img
                  className="object-contain "
                  src={
                    current === 0
                      ? data[0].image
                      : current === 1
                      ? data[1].image
                      : current === 2
                      ? data[2].image
                      : data[3].image
                  }
                  alt=""
                />
              </div>
              <div className="flex tablet:items-center max-w-[329px] mobile:mx-auto tablet:mx-0 mobile:pt-[22px]">
                <div className="space-y-[12px] w-full">
                  <h1 className="tablet:text-heading-text mobile:text-heading-text-mobile font-bold">
                    {current === 0
                      ? "Snacks"
                      : current === 1
                      ? "Drinks"
                      : current === 2
                      ? "Healthy"
                      : "Energy"}
                  </h1>
                  <p className="flex tablet:text-body-text mobile:text-body-text-mobile">
                    {
                      current === 0 ? "We have a vast inventory of your favorite snacks! Including Doritos, Lay’s Potato Chip, Oreos, Kitkat, Snickers, Sour Patch, Reese’s Peaunt Butter Cup, Pop Tarts, and more!"
                      : current === 1 ? "Nothing beats the sound of a freshly cracked soda! We provide an assortment of soft drinks, waters and juices." 
                      : current === 2 ? "After a workout, refill with some of our healthier options such as protein products and gramola bars." 
                      : "Charge up with your favorite coffe or energy drink!"
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* CARD FOR SERVICE */}
        <section id="section-services">
          <div className="tablet:px-[120px] tablet:py-[66px] mobile:px-[32px] mobile:py-[48px] bg-white">
            <div className="space-y-[32px]">
              <div className="space-y-[8px] justify-center text-center">
                <h1 className="desktop:text-heading-text mobile:text-heading-text-mobile font-bold">Services</h1>
                <p className="tablet:text-body-text mobile:text-body-text-mobile w-[255px] mx-auto">
                    Enjoy these FREE services to ensure constant satisfaction
                </p>
              </div>
              <div className="mobile:grid desktop:flex grid-rows-3 justify-center desktop:space-x-[20px] mobile:gap-y-[32px]">
                
              <div className="bg-linear-gredient mobile:min-w-[300px] mobile:h-[370px] tablet:w-full desktop:w-[350px] desktop:h-[370px] px-[42px] py-[31px]">
                  <div className="flex justify-center">
                    <img src={InstallationWorker} alt="" />
                  </div>
                  <h1 className="flex justify-center tablet:text-title-text mobile:text-title-text-mobile mt-[26px] font-bold primary-text ">
                    Installation
                  </h1>
                  <p className="tablet:text-body-text mobile:text-body-text-mobile text-center mt-[15px]">
                    We come and install your machine where you want, when you want.
                  </p>
                </div>

                <div className="bg-linear-gredient mobile:min-w-[300px] mobile:h-[370px] tablet:w-full desktop:w-[350px] desktop:h-[370px] px-[42px] py-[31px]">
                  <div className="flex justify-center">
                    <img className="h-[140px]" src={Restocking} alt="" />
                  </div>
                  <h1 className="flex justify-center tablet:text-title-text mobile:text-title-text-mobile mt-[26px] font-bold primary-text ">
                    Restocking
                  </h1>
                  <p className="tablet:text-body-text mobile:text-body-text-mobile text-center mt-[15px]">
                    Ran out of your favorite snacks? We provide services for restocking machines.
                  </p>
                </div>
                
                <div className="bg-linear-gredient mobile:min-w-[300px] mobile:h-[370px] tablet:w-full desktop:w-[350px] desktop:h-[370px] px-[42px] py-[31px]">
                  <div className="flex justify-center">
                    <img src={MaintenaceWorker} alt="" />
                  </div>
                  <h1 className="flex justify-center tablet:text-title-text mobile:text-title-text-mobile mt-[26px] font-bold primary-text ">
                    Maintenace
                  </h1>
                  <p className="tablet:text-body-text mobile:text-body-text-mobile text-center mt-[15px]">
                    We keep our machines in tip top shape with regular equipment check ups and technician on standby.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FOOTER */}
        <footer id="about-us" className=" ">
          <div className="bg-gradient-to-b from-[#b6dcff3d] to-[#ffffff] mobile:px-[32px] mobile:py-[48px] tablet:px-[120px] tablet:py-[66px]">
            <div className="flex tablet:flex-row mobile:flex-col justify-center mobile:space-y-[32px] tablet:space-y-[0px] tablet:space-x-[64px] items-start">
              
              <div className="text-center w-[300px] space-y-[12px] mobile:mx-auto tablet:mx-0">
                <div className="w-full text-center">
                  <img
                    className="mx-auto max-w-[200px]"
                    src={Logo}
                    alt=""
                  />
                </div>
                <p className="mobile:text-body-text-mobile tablet:text-body-text max-w-xs mx-auto tablet:mx-0">
                We provide professional services for installing and maintaining vending machines. 
                Our goal is to provide your business with its vending machine needs.
                Our team of professionals remove the hassle of acquiring and managing a vending machine.
                </p>
              </div>

              
              <div className="flex flex-col items-start w-[300px] mobile:space-y-[12px] tablet:space-y-[32px] mobile:mx-auto tablet:mx-0">
              
                <div className="mx-auto">
                  <h1 className="tablet:text-heading-text mobile:text-heading-text-mobile font-bold primary-text">
                    Contact Us
                  </h1>
                </div>

                <div className="mx-auto space-y-[24px]">
                  <div className="mx-auto text-center space-y-[12px]">
                    <p className="text-body-text">571-579-7683</p>
                    <p className="text-body-text">Alexandria, VA</p>
                    <p className="text-body-text">momentumvending1@gmail.com</p>
                  </div>

                </div>

              </div>
            </div>
            
            <div className="flex items-center justify-center space-x-[10px] font-medium text-center border-t border-gray-300 pt-[18px] mt-[18px]">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    d="M10.5 20.9199C4.98372 20.9199 0.5 16.4362 0.5 10.9199C0.5 5.40364 4.98372 0.919922 10.5 0.919922C16.0163 0.919922 20.5 5.40364 20.5 10.9199C20.5 16.4362 16.0163 20.9199 10.5 20.9199ZM10.5 2.31527C5.75581 2.31527 1.89535 6.17574 1.89535 10.9199C1.89535 15.6641 5.75581 19.5246 10.5 19.5246C15.2442 19.5246 19.1047 15.6641 19.1047 10.9199C19.1047 6.17574 15.2442 2.31527 10.5 2.31527Z"
                    fill="#292D32"
                  />
                  <path
                    d="M10.7233 15.3382C8.28608 15.3382 6.30469 13.3568 6.30469 10.9196C6.30469 8.48237 8.28608 6.50098 10.7233 6.50098C11.793 6.50098 12.8349 6.89167 13.6349 7.60795C13.9233 7.85912 13.9512 8.30563 13.7 8.594C13.4488 8.88237 13.0023 8.91029 12.714 8.65913C12.1651 8.1754 11.4581 7.90564 10.7233 7.90564C9.05815 7.90564 7.70004 9.26377 7.70004 10.9289C7.70004 12.594 9.05815 13.9521 10.7233 13.9521C11.4488 13.9521 12.1651 13.6824 12.714 13.1987C13.0023 12.9475 13.4488 12.9754 13.7 13.2638C13.9512 13.5521 13.9233 13.9987 13.6349 14.2498C12.8256 14.9475 11.793 15.3382 10.7233 15.3382Z"
                    fill="#292D32"
                  />
                </svg>
              </span>
              
              <div className="space-x-[5px]">
                <span>2023</span>

                <span>Momentum Vending</span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Home;
