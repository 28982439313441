import React from "react";

function DrinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      fill="none"
      viewBox="0 0 41 41"
    >
      <path
        fill="#4A90FF"
        d="M26.055 10.928h-11.11c-.955 0-1.735.775-1.735 1.735v2.565c0 2.3.055 12.305.075 15.41a1.22 1.22 0 00.99 1.19c.97.19 3.135.73 6.225.73s5.255-.54 6.225-.73a1.221 1.221 0 00.99-1.19c.02-3.11.075-13.115.075-15.41v-2.565c0-.96-.78-1.735-1.735-1.735z"
      ></path>
      <path
        fill="#71B2FF"
        d="M17.475 11.743s-1.665 1.635-1.665 4.12v14.75l-4.09-.82.19-18.895 5.565.845z"
      ></path>
      <path
        fill="#262626"
        d="M25.49 10.928a2.3 2.3 0 012.3 2.3v2c0 2.3-.055 12.305-.075 15.41a1.22 1.22 0 01-.99 1.19c-.97.19-3.135.73-6.225.73s-5.255-.545-6.225-.735a1.22 1.22 0 01-.99-1.19c-.02-3.11-.075-13.115-.075-15.41v-2a2.3 2.3 0 012.3-2.3h9.98m0-1.885h-9.98a4.19 4.19 0 00-4.185 4.185v2c0 2.305.055 12.385.075 15.42a3.121 3.121 0 002.515 3.03c.11.02.24.05.385.08 1.15.24 3.295.685 6.205.685s5.05-.445 6.205-.685c.145-.03.27-.055.385-.08a3.117 3.117 0 002.515-3.03c.02-3.035.075-13.115.075-15.42v-2c-.01-2.305-1.89-4.185-4.195-4.185z"
      ></path>
      <path
        fill="#E4F1F7"
        d="M20.5 11.603c-7.58 0-7.58-1.445-7.58-1.99 0-.545 0-1.99 7.58-1.99s7.58 1.445 7.58 1.99c0 .545 0 1.99-7.58 1.99z"
      ></path>
      <path
        fill="#262626"
        d="M20.5 8.568c3.665 0 6.635.47 6.635 1.05 0 .58-2.97 1.05-6.635 1.05-3.665 0-6.635-.47-6.635-1.05 0-.58 2.97-1.05 6.635-1.05zm0-1.89c-1.87 0-3.64.115-4.985.33-1.06.165-3.535.56-3.535 2.605s2.48 2.44 3.535 2.605c1.345.215 3.12.33 4.985.33 1.87 0 3.64-.115 4.985-.33 1.06-.165 3.535-.56 3.535-2.605s-2.48-2.44-3.535-2.605c-1.345-.21-3.115-.33-4.985-.33z"
      ></path>
      <path
        fill="#E4F1F7"
        d="M20.5 33.498c-2.81 0-4.895-.435-6.01-.665-.15-.03-.28-.06-.395-.08a2.166 2.166 0 01-1.75-2.11v-.18a.948.948 0 011.18-.915c.03.01 3.145.805 6.965.805s6.955-.795 6.99-.805a.947.947 0 01.815.17c.23.18.365.455.365.745v.17a2.166 2.166 0 01-1.75 2.115c-.115.02-.245.05-.395.08-1.125.235-3.205.67-6.015.67z"
      ></path>
      <path
        fill="#262626"
        d="M13.285 30.463s3.2.835 7.2.835 7.23-.835 7.23-.835v.17c0 .585-.415 1.075-.99 1.19-.97.19-3.135.73-6.225.73s-5.255-.54-6.225-.73a1.221 1.221 0 01-.99-1.19v-.17zm14.43-1.885c-.16 0-.315.02-.47.06-.03.005-3.06.775-6.75.775s-6.7-.765-6.73-.775a1.878 1.878 0 00-1.625.335c-.46.355-.73.91-.73 1.49v.17a3.12 3.12 0 002.515 3.04c.11.02.24.05.385.08 1.15.24 3.295.685 6.205.685 2.905 0 5.05-.445 6.205-.685.145-.03.27-.055.385-.08a3.113 3.113 0 002.515-3.04v-.17a1.88 1.88 0 00-.73-1.49 1.99 1.99 0 00-1.175-.395z"
      ></path>
      <path
        fill="#BECFD3"
        d="M21.86 9.613c0 .074.2.146.555.198.355.053.838.082 1.34.082.503 0 .985-.03 1.34-.082.356-.052.555-.124.555-.198 0-.074-.2-.145-.555-.198a9.733 9.733 0 00-1.34-.082c-.502 0-.985.03-1.34.082-.355.053-.555.124-.555.198z"
      ></path>
      <path
        fill="#fff"
        d="M20.745 16.933a3.271 3.271 0 010 6.54 3.271 3.271 0 010-6.54zm0-.38a3.654 3.654 0 00-3.65 3.65c0 2.01 1.635 3.65 3.65 3.65 2.01 0 3.65-1.635 3.65-3.65 0-2.015-1.64-3.65-3.65-3.65z"
      ></path>
      <path
        fill="#F45D5D"
        d="M17.475 20.203a3.27 3.27 0 106.54 0 3.27 3.27 0 00-6.54 0z"
      ></path>
      <path
        fill="#4A90FF"
        d="M22.97 17.808l-2.225 3.515-2.45 1.035a3.26 3.26 0 002.45 1.11 3.271 3.271 0 003.27-3.27c0-.94-.4-1.79-1.045-2.39z"
      ></path>
      <path
        fill="#F9F9F9"
        d="M22.325 17.338c-.005.015-.995 3.955-4.195 4.82.145.19.305.365.485.525.47-.19 1.515-.685 2.2-.85.66-.16 3.2-1.145 2.92-2.95-.29-.66-.785-1.2-1.41-1.545z"
      ></path>
    </svg>
  );
}

export default DrinkIcon;