import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import  Home from "./pages/Home.js";
import Contact from "./pages/Contact.js";
  
  

function App() { 
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/contact-us" element={<Contact />} /> 
        </Routes>
      </Router>
  );
}


export default App;