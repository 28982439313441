import React from "react";

function HealthyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      fill="none"
      viewBox="0 0 41 41"
    >
      <path
        fill="#FEFE71"
        d="M22.71 11.558a5.163 5.163 0 00-4 3.367 5.136 5.136 0 001 5.133 5.154 5.154 0 003-8.5z"
      ></path>
      <path
        fill="#D6CD1E"
        d="M22.71 11.558a5.163 5.163 0 00-4 3.367 5.136 5.136 0 001 5.133l3-8.5z"
      ></path>
      <path
        fill="#7DAC51"
        d="M19.133 21.129s-.797-7.133-5.172-7.133-5.375 4.14-2.531 4.14c2.843 0 6.75 1.383 6.75 3h.953v-.007z"
      ></path>
      <path
        fill="#5B7C31"
        d="M18.719 19.16c0-.023 0-.047-.008-.063a5.345 5.345 0 00-.14-.468c-.727-1.375-4.079-2.461-6.61-2.461-.82 0-1.32-.344-1.516-.828-1.07 1.21-.78 2.797.977 2.797 2.844 0 6.75 1.382 6.75 3h.945s-.094-.852-.398-1.962v-.015z"
      ></path>
      <path
        fill="#463218"
        d="M19.71 20.597a.515.515 0 01-.405-.187 5.713 5.713 0 01-1.102-5.672 5.703 5.703 0 014.422-3.719.549.549 0 01.5.18 5.713 5.713 0 011.102 5.672 5.703 5.703 0 01-4.422 3.719c-.04.007-.063.007-.094.007zm2.79-8.437a4.606 4.606 0 00-2.578 7.289 4.606 4.606 0 003.273-2.945 4.634 4.634 0 00-.695-4.344z"
      ></path>
      <path
        fill="#463218"
        d="M19.133 21.675h-.945a.544.544 0 01-.547-.546c0-1.016-3.211-2.454-6.203-2.454-1.07 0-2.149-.578-2.149-1.875 0-.804.469-1.664 1.258-2.28.617-.485 1.703-1.071 3.43-1.071 2.03 0 3.664 1.351 4.734 3.906.766 1.836.977 3.64.984 3.719a.541.541 0 01-.133.422.615.615 0 01-.43.18zm-5.172-7.132c-1.406 0-2.266.453-2.742.828-.516.414-.844.96-.844 1.43 0 .68.664.78 1.055.78 1.492 0 3.226.352 4.648.93 1.125.461 1.906 1.008 2.32 1.61-.398-1.875-1.562-5.578-4.437-5.578z"
      ></path>
      <path fill="#5B7C31" d="M14.914 29.394h9.727v2.282h-9.727v-2.282z"></path>
      <path
        fill="#7DAC51"
        d="M35.695 13.996a3.122 3.122 0 00-5.453 0 3.122 3.122 0 005.453 0z"
      ></path>
      <path
        fill="#7DAC51"
        d="M30.156 9.37a2.501 2.501 0 00-1.289 2.196c0 .945.524 1.766 1.29 2.195a2.501 2.501 0 001.288-2.195 2.51 2.51 0 00-1.289-2.195z"
      ></path>
      <path
        fill="#FE8E46"
        d="M30.07 13.996a2.427 2.427 0 00-3.578.156l-7.75 9.203a1.396 1.396 0 001.969 1.969l9.203-7.75a2.427 2.427 0 00.156-3.578z"
      ></path>
      <path
        fill="#463218"
        d="M19.813 26.199c-.5 0-.993-.195-1.375-.57a1.93 1.93 0 01-.11-2.625l7.75-9.204a2.983 2.983 0 014.383-.188 2.983 2.983 0 01-.188 4.384l-9.203 7.75a1.961 1.961 0 01-1.258.453zm8.546-12.375h-.086a1.85 1.85 0 00-1.359.672l-7.75 9.203a.844.844 0 00.047 1.148.844.844 0 001.148.047l9.203-7.75c.407-.343.649-.828.672-1.36a1.87 1.87 0 00-1.875-1.96z"
      ></path>
      <path
        fill="#463218"
        d="M25.602 17.566a.52.52 0 01-.383-.164l-.828-.828a.552.552 0 010-.774.552.552 0 01.773 0l.828.829a.552.552 0 01-.39.937zm-1.797 5.703a.52.52 0 01-.383-.164l-.758-.758a.552.552 0 010-.773.552.552 0 01.773 0l.758.758a.552.552 0 01-.39.937zm9.164-7.125a3.667 3.667 0 01-3.203-1.882.537.537 0 010-.532 3.667 3.667 0 016.406 0 .537.537 0 010 .531 3.683 3.683 0 01-3.203 1.883zm-2.078-2.148a2.583 2.583 0 002.078 1.054c.828 0 1.593-.398 2.078-1.054a2.583 2.583 0 00-2.078-1.055c-.828 0-1.594.39-2.078 1.055z"
      ></path>
      <path
        fill="#463218"
        d="M30.156 14.316a.54.54 0 01-.265-.07 3.059 3.059 0 010-5.344.537.537 0 01.53 0 3.059 3.059 0 010 5.344.54.54 0 01-.265.07zm0-4.289a1.955 1.955 0 00-.742 1.54c0 .6.281 1.171.742 1.538a1.967 1.967 0 000-3.078zM24.64 32.222h-9.726a.544.544 0 01-.547-.547v-2.28c0-.305.242-.548.547-.548h9.727c.304 0 .547.243.547.547v2.282a.544.544 0 01-.547.546zm-9.18-1.093h8.634V29.94H15.46v1.188z"
      ></path>
      <path
        fill="#FEFE71"
        d="M9.71 20.574c0 3.797 2.102 7.101 5.204 8.82h9.727v.008a10.07 10.07 0 005.21-8.828H9.712z"
      ></path>
      <path
        fill="#D6CD1E"
        d="M15.117 20.574H9.711c0 3.797 2.101 7.101 5.203 8.82h5.406a10.066 10.066 0 01-5.203-8.82z"
      ></path>
      <path
        fill="#463218"
        d="M24.64 29.949c-.023 0-.046 0-.07-.008h-9.656a.52.52 0 01-.266-.07 10.645 10.645 0 01-5.492-9.297c0-.305.242-.547.547-.547h20.149c.304 0 .546.242.546.547 0 3.867-2.109 7.43-5.5 9.305a.492.492 0 01-.257.07zm-9.578-1.102h9.446a9.553 9.553 0 004.789-7.726H10.273a9.523 9.523 0 004.79 7.726z"
      ></path>
      <path
        fill="#7DAC51"
        d="M9.938 22.722h19.687c.148-.687.227-1.406.227-2.14H9.71c0 .726.078 1.445.226 2.14z"
      ></path>
      <path
        fill="#463218"
        d="M29.625 23.27H9.937a.544.544 0 01-.53-.43c-.165-.743-.243-1.5-.243-2.258 0-.305.242-.547.547-.547h20.148c.305 0 .547.242.547.547 0 .758-.078 1.515-.242 2.258a.552.552 0 01-.539.43zm-19.234-1.095h18.78a9.34 9.34 0 00.118-1.046H10.273c.024.343.063.695.118 1.046z"
      ></path>
      <path
        fill="#fff"
        d="M15.477 27.605c-.868-.718-2.375-2.734-2.438-2.82l.875-.656c.414.554 1.625 2.109 2.258 2.64l-.695.836z"
      ></path>
    </svg>
  );
}

export default HealthyIcon;