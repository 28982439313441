import React from "react";

function EnergyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      fill="none"
      viewBox="0 0 41 41"
    >
      <path
        fill="#FE8E46"
        d="M24.453 15.972a6.665 6.665 0 01-1.953-4.71v-1.243h-4v1.242c0 1.766-.703 3.461-1.953 4.711a6.996 6.996 0 00-2.047 4.946v9.781a2.41 2.41 0 002.414 2.414h7.172a2.41 2.41 0 002.414-2.414v-9.781c0-1.86-.734-3.633-2.047-4.946z"
      ></path>
      <path
        fill="#E56823"
        d="M18.5 30.699v-9.781c0-1.86.734-3.633 2.047-4.946a6.665 6.665 0 001.953-4.71v-1.243h-4v1.242c0 1.766-.703 3.461-1.953 4.711a6.996 6.996 0 00-2.047 4.946v9.781a2.41 2.41 0 002.414 2.414h4a2.41 2.41 0 01-2.414-2.414z"
      ></path>
      <path
        fill="#463218"
        d="M24.086 33.66h-7.172a2.964 2.964 0 01-2.96-2.961v-9.781c0-2.016.78-3.906 2.21-5.336a6.063 6.063 0 001.79-4.32v-1.243c0-.304.241-.547.546-.547h4c.305 0 .547.243.547.547v1.242a6.05 6.05 0 001.789 4.32 7.506 7.506 0 012.21 5.337v9.781a2.964 2.964 0 01-2.96 2.96zm-5.04-23.094v.695c0 1.922-.75 3.735-2.108 5.094a6.405 6.405 0 00-1.891 4.563v9.781c0 1.031.836 1.867 1.867 1.867h7.172a1.867 1.867 0 001.867-1.867v-9.781a6.419 6.419 0 00-1.89-4.563 7.163 7.163 0 01-2.11-5.094v-.695h-2.906z"
      ></path>
      <path fill="#FEFE71" d="M17.367 7.613h6.266v4.68h-6.266v-4.68z"></path>
      <path fill="#D6CD1E" d="M17.367 7.613h2.352v4.68h-2.352v-4.68z"></path>
      <path
        fill="#463218"
        d="M23.633 12.832h-6.266a.544.544 0 01-.547-.547V7.613c0-.305.242-.547.547-.547h6.266c.305 0 .547.242.547.547v4.68c0 .297-.25.539-.547.539zm-5.719-1.094h5.172V8.16h-5.172v3.578z"
      ></path>
      <path
        fill="#FEFE71"
        d="M25.867 18.02h-1.89l-5.82 6.726h5.757L20.062 30.8l6.438-5.696V22.02h-2.086l1.906-2.656a6.633 6.633 0 00-.453-1.344z"
      ></path>
      <path
        fill="#463218"
        d="M20.063 31.347a.551.551 0 01-.46-.844l3.319-5.218h-4.766a.559.559 0 01-.5-.32.554.554 0 01.086-.586l5.828-6.727a.557.557 0 01.414-.187h1.891c.211 0 .406.125.5.32.211.46.375.945.484 1.445a.57.57 0 01-.086.438l-1.289 1.797h1.024c.305 0 .547.242.547.547v3.085a.54.54 0 01-.188.407l-6.445 5.703a.536.536 0 01-.36.14zm-.711-7.148h4.562a.54.54 0 01.477.281.562.562 0 01-.016.555l-1.766 2.781 3.352-2.96v-2.29h-1.54a.55.55 0 01-.444-.867l1.757-2.453a4.96 4.96 0 00-.226-.68h-1.274L19.352 24.2z"
      ></path>
      <path
        fill="#fff"
        d="M17.594 19.863H16.5c0-1.867 1.984-3.766 2.07-3.851l.75.796c-.476.461-1.726 1.899-1.726 3.055z"
      ></path>
    </svg>
  );
}

export default EnergyIcon;